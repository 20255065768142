<template>
  <div class="rf-input-autocomplete">
    <RFInput :label="label" :color="color" :value="value" @input="search = $event"
      @focusout="focusOut" @focusin="focusIn"
      @key-down="scrollDown" @key-up="scrollUp" @key-enter="selectItem(filteredItems[selectedIndex])" :readonly="disabled"/>
    <ul class="results" :class="{ 'is-empty': !hasResults }" v-if="isOpen" ref="results" :style="resultsStyle">
      <li class="result"
      :ref="`result-${index}`"
      :class="{
        'selected': selectedIndex === index,
      }" @click="selectItem(item)" v-for="(item, index) in filteredItems" :key="index">{{item}}</li>
    </ul>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'

export default {
  name: 'RFInputAutocomplete',
  components: {
    RFInput,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    label: String,
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#24425B',
    },
    multipleInput: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      search: null,
      isOpen: false,
      filteredItems: [],
      selectedIndex: 0,
    }
  },
  computed: {
    resultsStyle() {
      return {
        '--color': this.color,
      }
    },
    hasResults() {
      return !!this.filteredItems.length
    }
  },
  watch: {
    search: {
      handler() {
        if (this.search && this.search.trim() !== '' && this.value !== this.search) {
          this.isOpen = true
          this.filteredItems = this.items.filter(item => {
            return this.search.toLowerCase().indexOf(item.toLowerCase()) > -1
                  || item.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          })

          this.selectedIndex = 0
        } else {
          this.filteredItems = []
          this.isOpen = false
        }
      }
    }
  },
  mounted() {
    this.search = this.value || null
    this.isOpen = false
  },
  methods: {
    scrollUp() {
      if (this.selectedIndex > 0) {
        this.selectedIndex -= 1
        this.updateSelectedItem()
      }
    },
    scrollDown() {
      if (this.selectedIndex < this.filteredItems.length - 1) {
        this.selectedIndex += 1
        this.updateSelectedItem()
      }
    },
    updateSelectedItem() {
      const selected = this.$refs[`result-${this.selectedIndex}`][0]

      if (selected) {
        this.isOpen = true
        this.$refs.results.scroll({ top: selected.offsetTop })
      }
    },
    selectItem(item) {
      this.search = item
      this.selectedIndex = 0
      this.$emit('input', this.search)
      this.$emit('select', this.search)

      if (this.multipleInput) {
        this.search = ''
      }

      this.focusOut()
    },
    focusIn() {
      this.filteredItems = this.items
      this.isOpen = !this.disabled
    },
    focusOut() {
      window.setTimeout(() => {
        this.isOpen = false
      }, 200)
    }
  }
}
</script>

<style lang="scss" scoped>
.rf-input-autocomplete {
  position: relative;

  .results {
    position: absolute;
    background-color: $white;
    width: 100%;
    z-index: 10;
    border: 1px solid var(--color);
    border-top: none;
    list-style: none;
    padding: 0;
    max-height: 100px;
    overflow-y: auto;

    &.is-empty {
      border: 1px solid transparent;
    }

    .result {
      @include font-style($montserrat, 'medium', $font-14);
      padding: 5px 15px;
      cursor: pointer;

      &.selected {
        background-color: var(--color);
        color: $white;
      }
      &:hover {
        background-color: var(--color);
        color: $white;
      }
    }
  }
}
</style>
