<template>
  <div class="rf-file">
    <RFButton :size="size" :label="label" :background="background" :color="color" :border-color="borderColor" :full-width="fullWidth" @on-click="openFileUpload" v-if="!filename || !hideAfterUpload"/>
    <input class="input-file" :accept="allowedExtensions" type="file" ref="file" @change="onUpload" :multiple="multiple"/>
  </div>
</template>

<script>
import RFButton from '@/components/forms/RFButton'
import _reduce from 'lodash/reduce'

export default {
  components: {
    RFButton,
  },
  props: {
    fullWidth: {
      default: false,
      type: Boolean,
    },
    background: {
      type: String,
      default: '#24425B',
    },
    color: {
      type: String,
      default: '#fff',
    },
    borderColor: {
      type: String,
      default: '#fff',
    },
    label: {
      type: String,
      default: 'Upload',
    },
    hideAfterUpload: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'medium',
    },
    acceptedFiles: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      filename: null,
    }
  },
  computed: {
    allowedExtensions() {
      return this.acceptedFiles.length ?
            _reduce(this.acceptedFiles, (accumulator, file, index) => {
              accumulator = `${accumulator}.${file}`
              return index === this.acceptedFiles.length - 1 ? accumulator : `${accumulator},`
            }, '')
            : '.jpeg,.jpg,.png,.pdf'
    }
  },
  methods: {
    openFileUpload() {
      if (!this.disabled) {
        this.$refs.file.click()
      }
    },
    onUpload(e) {
      const files = e.target.files || e.dataTransfer.files

      if (!this.multiple) {
        const file = files[0]
        if (file) {
          let extension = file.type.split('/')[1]
          if(extension === 'vnd.openxmlformats-officedocument.wordprocessingml.document'){
            extension = 'docx'
          }

          if (this.acceptedFiles.length) {
            if (this.acceptedFiles.includes(extension)) {
              this.$emit('file', file)
            } else {
              this.$toasted.error(this.$i18n.t(`You have uploaded a file with extension not allowed`))
            }
          } else {
            if (this.allowedExtensions.includes(extension)) {
              this.$emit('file', file)
            } else {
              this.$toasted.error(this.$i18n.t(`You have uploaded a file with extension not allowed`))
            }
          }
        }
      } else {
        let validFilesExtension = true
        let i = 0
        while(validFilesExtension && i< files.length){
          const file = files[i]
          if (file) {
            const extension = file.type.split('/')[1]

            if (this.acceptedFiles.length) {
              if (!this.acceptedFiles.includes(extension)) {
                validFilesExtension = false
              }
              else{
                i++
              }
            }
            else {
              if (!this.allowedExtensions.includes(extension)) {
                validFilesExtension = false
              }
              else{
                i++
              }
            }
          }
          else{
            validFilesExtension = false
          }
        }
        if(validFilesExtension){
          this.$emit('files', files)
        }
        else{
          this.$toasted.error(this.$i18n.t(`You have uploaded a file with extension not allowed`))
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rf-file {
  .input-file {
    display: none;
  }
}
</style>
