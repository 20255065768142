<template>
  <div class="rf-upload-document" :class="{
    'justify-content-between': isAlreadyUploaded(docType),
  }">
    <div class="already-uploaded mt-3" v-if="isAlreadyUploaded(docType) && !isInDocuments(docType)">
      <span class="rf-success-color mr-2">
        <FontAwesomeIcon color="#5CB85C" :icon="['fa', 'check-circle']"/>
      </span>
      <span class="doc text-uppercase rf-white-color ml-2">
        {{$t(`${docName}`)}}
      </span>
    </div>
    <!-- <div class="not-already-uploaded mt-3" v-else>
      <div class="uploaded-document" v-if="isInDocuments(docType, docSide)">
        <div class="d-inline cursor-pointer" @click="$emit('show')">
          <FontAwesomeIcon class="mx-2" color="#fff" :icon="['fas', 'eye']"/>
        </div>
        <div class="d-inline cursor-pointer" @click="$emit('remove')">
          <FontAwesomeIcon class="mr-3" color="#FF5A5F" :icon="['fas', 'trash']"/>
        </div>
        <RFButton size="small" :label="$t('Send')" background="#24425B" color="#fff" border-color="#74776D" @on-click="$emit('send')"/>
      </div>
    </div> -->
    <RFFile
      size="small"
      class="mt-3"
      :accepted-files="acceptedFiles"
      :label="isAlreadyUploaded(docType) ? $t('Modify') : $t('Upload')"
      background="#24425B" color="#fff" border-color="#74776D"
      @file="$emit('upload', $event)"
      v-show="!disableModify"
      v-if="(isMandatory ? !isVerifiedStatus && !isInDocuments(docType) : !isInDocuments(docType)) "/>
  </div>
</template>

<script>
import RFFile from '@/components/forms/RFFile'
import RFButton from '@/components/forms/RFButton'

export default {
  name: 'RFUploadDocument',
  components: {
    RFFile,
    RFButton,
  },
  props: {
    disableModify: Boolean,
    docName: String,
    docType: String,
    docSide: String,
    documents: Array,
    userDocuments: Array,
    isVerifiedStatus: Boolean,
    isMandatory: {
      type: Boolean,
      default: false,
    },
    acceptedFiles: {
      type: Array,
      default: () => ['jpeg', 'jpg', 'png', 'pdf'],
    },
  },
  methods: {
    isAlreadyUploaded(docType) {
      return this.userDocuments.find(document => document.docType === docType)
    },
    isInDocuments(docType, docSide) {
      return this.documents.find(document => document.docType === docType)
    }
  }
}
</script>

<style lang="scss" scoped>
.rf-upload-document {
  display: flex;
  align-items: center;

  .already-uploaded {
    .doc {
      @include font-style($montserrat, 'medium', $font-12);
    }
  }
}

</style>
