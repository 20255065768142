<template>
  <b-modal class="rf-modal" :ref="modalId" :id="modalId" :size="size" :ok-disabled="true" :cancel-disabled="true" :hide-footer="hideFooter" @shown="$emit('shown')"
  @hidden="$emit('hidden');removeConfirm()">
    <template v-slot:modal-title>
      <h2 class="title">{{$t(title)}}</h2>
    </template>

    <slot/>

    <template v-slot:modal-footer>
      <RFButton :label="okLabel" background="#5CB85C" border-color="transparent" @on-click="$emit('send')" v-if="!loading && !confirm"/>
      <RFButton :label="cancelLabel" background="#FF5A5F" border-color="transparent" @on-click="confirm = true" v-if="!loading && hasCancel && !confirm"/>
      <RFButton label="" :icon="['fas', 'check']" background="#5CB85C" border-color="transparent" @on-click="cancel" v-if="confirm"/>
      <RFButton label="" :icon="['fas', 'times']" background="#FF5A5F" border-color="transparent" @on-click="removeConfirm" v-if="confirm"/>
      <RFLoader :size="35" color="#24425B" v-if="loading"/>
    </template>
  </b-modal>
</template>

<script>
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'

export default {
  name: 'RFModal',
  components: {
    RFButton,
    RFLoader,
  },
  props: {
    modalId: String,
    size: {
      type: String,
      default: 'xl',
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    title: String,
    loading: {
      type: Boolean,
      default: false,
    },
    hasCancel: {
      type: Boolean,
      default: false,
    },
    okLabel: String,
    cancelLabel: String,
  },
  data() {
    return {
      confirm: false,
    }
  },
  methods: {
    cancel() {
      this.$refs[this.modalId].hide()
      this.$emit('cancel')
    },
    removeConfirm(){
      this.confirm = false;
    }
  }
}
</script>

<style>
.modal-content {
    border-radius: 0 !important;
    border: 1px solid white;
    min-height: 90vh;
}
.modal-footer {
      background-color: #24425b;
      color: white;
      border-radius: 0;
}
.modal-header {
      background-color: #24425b;
      color: white;
      border-radius: 0;
}
.modal .close {
  outline: none;
  color: white;
}
</style>
