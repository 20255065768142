<template>
  <div class="rf-checkbox" :style="checkboxStyle" @click="!readonly ? check() : null">
    <div class="rf-checkbox-label">{{label}}</div>
    <div class="checkbox" :class="{ checked }">
      <FontAwesomeIcon class="check" :icon="['fas', 'check']" :color="color === '#fff' ? '#24425B' : '#fff'" v-if="checked"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RFCheckbox',
  props: {
    label: String,
    name: String,
    color: {
      type: String,
      default: '#24425B',
    },
    value: {
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      checked: false,
    }
  },
  beforeMount() {
    this.checked = this.value
  },
  computed: {
    checkboxStyle() {
      return {
        '--color': this.color,
      }
    }
  },
  methods: {
    check() {
      this.checked = !this.checked
      this.$emit('input', this.checked)
    }
  }
}
</script>

<style lang="scss" scoped>
.rf-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;

  .rf-checkbox-label {
    @include font-style($montserrat, 'semibold', $font-14);
    color: var(--color);
  }
  .checkbox {
    position: relative;
    width: 15px;
    height: 15px;
    margin-left: 10px;
    border-radius: 3px;
    border: 1px solid var(--color);
    transition: background-color .3s;

    &.checked {
      background-color: var(--color);
    }
    .check {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
    }
  }
}
</style>