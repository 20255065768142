<template>
  <RFModal :title="`${$t(docType)} (${$t(docSide)})` | replaceUnderscore | capitalize" :modalId="modalId" hide-footer>
    <div class="document-container">
      <object class="document" v-if="isPdf" :data="image" :type="document.document.type"/>
      <img class="document-image" :src="image" v-else/>
    </div>
  </RFModal>
</template>

<script>
import RFModal from '@/components/modals/RFModal'
import { mapState } from 'vuex'
import { getAsset } from '@/helpers/filters'

import _isEmpty from 'lodash/isEmpty'

export default {
  name: 'DocumentModal',
  components: {
    RFModal,
  },
  data() {
    return {
      modalId: 'document-modal',
      image: null,
    }
  },
  computed: {
    ...mapState({
      document: state => state.documentStore.document,
    }),
    isPdf() {
      return this.documentType === 'application/pdf'
    },
    docType() {
      return !(_isEmpty(this.document)) ? this.document.docType : null
    },
    docSide() {
      return !(_isEmpty(this.document)) ? this.document.docSide : null
    },
    file() {
      return this.document ? this.document.document : null
    },
    documentType() {
      return !(_isEmpty(this.document)) ? this.document.document.type : null
    },
  },
  watch: {
    document: {
      handler() {
        this.image = URL.createObjectURL(this.file)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.document-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 70vh;
  border: 1px solid $gray;

  .document {
    max-width: 100%;
    width: 100%;
    height: 70vh;
  }
  .document-image {
    width: 100%;
    height: 100%;
  }
}
</style>
